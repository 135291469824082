<template>
  <div class="pre-loader">
      <div class="lds-ripple"><div></div><div></div></div>
      <div style="min-height:75px">
        <ebba-logo inverted></ebba-logo>
      </div>
  </div>
</template>

<script>
export default {
    components: {
        EbbaLogo: () => import("@/components/ebba/ebba-logo")
    },
    data() {
        return {

        }
    }
}
</script>

<style lang="scss">
.pre-loader {
    background-color: var(--brand-color-1);
    position: fixed;
    top: 0; left: 0; bottom: 0; right: 0;
    z-index: 200;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .ebba-logo {
        width: 120px;
    }
    .lds-ripple {
        display: inline-block;
        position: relative;
        width: 80px;
        height: 100px;
        transform: scale(.8);
    }
    .lds-ripple div {
        position: absolute;
        border: 4px solid rgba(255, 255, 255, 0.683);
        opacity: 1;
        border-radius: 50%;
        animation: lds-ripple 1.35s cubic-bezier(0, 0.2, 0.8, 1) infinite;
    }
    .lds-ripple div:nth-child(2) {
        animation-delay: -0.5s;
    }
    
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

</style>